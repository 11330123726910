import { useCallback, useEffect, useState } from "react";

export const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState(false);

  const darkModeChange = useCallback((event: MediaQueryListEvent) => {
    setDarkMode(event.matches);
  }, []);

  useEffect(() => {
    const windowQuery = window.matchMedia("(prefers-color-scheme:dark)");
    windowQuery.addEventListener("change", darkModeChange);
    return () => {
      windowQuery.removeEventListener("change", darkModeChange);
    };
  }, [darkModeChange]);

  useEffect(() => {
    const windowQuery = window.matchMedia("(prefers-color-scheme:dark)");
    setDarkMode(windowQuery.matches);
  }, []);

  return darkMode;
};
